import { ProductPlan } from '~/src/api/PolicyAPI';

interface GetCreateMyWorkActionURIParams {
  templateId: string;
}

type GetPaymentSubscriptionParams = ProductPlan;

interface GetSigninActionURIParams {
  redirectUri?: string;
}

export const getCreateMyWorkActionURI = (path: string, params: GetCreateMyWorkActionURIParams) => {
  const qs = new URLSearchParams({
    'action': 'create_mywork',
    'action_params_templateId': params.templateId,
  });

  return `${path}?${qs}`;
};

export const getPaymentSubscriptionActionURI = (path: string, params: GetPaymentSubscriptionParams) => {
  const qs = new URLSearchParams({
    'action': 'payment_payple',
    'action_params_currency': params.currency,
    'action_params_planId': params.planId,
    'action_params_planType': params.planType,
    'action_params_planName': params.planName,
    'action_params_price': params.price,
  });

  return `${path}?${qs}`;
};

export const getSigninActionURI = (path: string, params: GetSigninActionURIParams) => {
  const qs = new URLSearchParams({
    'action': 'signin',
    'action_params_redirectUri': params.redirectUri ?? '',
  });

  return `${path}?${qs}`;
};
