import { useMutation } from '@tanstack/react-query';

import { myWorksAPIClient } from '../../../api/MyWorksAPI';
import { useAuth } from '../../Account/useAuth';
import { CreateMyWorkPayload } from './useMyWorkManager.types';

export const useMyWorkManager = () => {
  const { userId, name } = useAuth();

  const createMyWork = useMutation({
    mutationKey: ['create', 'my-work'],
    mutationFn: ({ templateId, currentCategoryId }: CreateMyWorkPayload) => {
      if (!userId) throw { code: 400, message: 'Require User ID' };
      if (!name) throw { code: 400, message: 'Require User Name' };

      return myWorksAPIClient.addItem(
        userId,
        {
          author: name,
          templateId,
          metadata: {
            relatedCategoryId: currentCategoryId,
          },
        }
      );
    },
  });

  return {
    createMyWork,
  };
};
