import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { Nl2br } from '~/src/components/app/Nl2br/Nlbr';
import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

type BenefitsSectionTitleContainerProps = ComponentProps

export const BenefitsSectionTitleContainer = (props: BenefitsSectionTitleContainerProps) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const tablet = useMediaQuery('tablet');

  return (
    <div
      className={classNames(
        'BenefitsSection__TitleContainer',
        props.className
      )}
      css={css`
        text-align: center;
      `}
    >
      <Typography
        variant={tablet.down.is ? 'h4' : 'h1'}
        css={css`
          color: ${theme.app.colors['neutral-color-01']};
        `}
      >
        <Nl2br>
          { t('common:benefits.title') }
        </Nl2br>
      </Typography>
      <Typography
        variant="body4"
        css={css`
          margin-top: 30px;
          color: ${theme.app.colors['neutral-color-05']};
        `}
      >
        <Nl2br>
          { t('common:benefits.description') }
        </Nl2br>
      </Typography>
    </div>
  );
};
