import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { templateAPIClient } from '~/src/api/TemplatesAPI';

interface UseTemplateLoaderArguments {
  categoryId?: string;
}

export const useTemplateLoader = (args: UseTemplateLoaderArguments) => {
  const templateQuery = useQuery({
    queryKey: ['template', args.categoryId],
    queryFn: async () => {
      const categoryId = args.categoryId ?? '';

      const params = {
        select: 'id,thumbnailUrl,categories',
        offset: 0,
        limit: 30,
      };
      return templateAPIClient.getTemplateListByCategoryId(categoryId, params).then((response) => response.data);
    },
    enabled: Boolean(args.categoryId),
  });

  const templates = useMemo(() => templateQuery.data?.result, [templateQuery.data]);

  const upsideMarqueeTemplates = useMemo(() => {
    if (templates === undefined) return undefined;

    return templates.slice(0, templates.length / 2 - 1);
  }, [
    templates,
  ]);

  const downsideMarqueeTemplates = useMemo(() => {
    if (templates === undefined) return undefined;

    return templates.slice(templates.length / 2, templates.length - 1);
  }, [
    templates,
  ]);

  return {
    templateQuery,
    upsideMarqueeTemplates,
    downsideMarqueeTemplates,
  };
};
