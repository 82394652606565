import { css } from '@emotion/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { LoadingOverlay } from '~/src/components/app/LoadingOverlay';
import { CategoriesList } from '~/src/components/business/CategoriesList';
import { AmplitudeTaxonomyClient, SelectIndustryCategoryEventProperties } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useMyWorkManager } from '~/src/hooks/MyWorks/useMyWorkManager';
import { useHomePolicy } from '~/src/hooks/Policy/useHomePolicy';
import { getCreateMyWorkActionURI } from '~/src/utils/action-route';

import { TemplateMarquee } from '../../screens/Welcome/components/TemplateMarquee/TemplateMarquee';
import { useTemplateLoader } from '../../screens/Welcome/hooks/useTemplateLoader/hook';
import { useAccountDialog } from '../Dialogs';
import { TemplateBrowseContext } from './context/context';

export interface TemplateBrowseProps extends HTMLAttributes<HTMLDivElement> {
  location: SelectIndustryCategoryEventProperties['location'];
}

export const TemplateBrowse = (props: TemplateBrowseProps) => {
  const { location } = props;

  const router = useRouter();
  const { createMyWork } = useMyWorkManager();
  const { selectedCategoryId, setSelectedCategoryId } = TemplateBrowseContext();
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>();
  const { show: showAccountDialog } = useAccountDialog();

  const { stringSlideItems } = useHomePolicy();
  const { upsideMarqueeTemplates, downsideMarqueeTemplates } = useTemplateLoader({ categoryId: selectedCategoryId });
  const categories = useMemo(() => stringSlideItems.map((item) => ({ id: item.id, icon: item.imageLink, title: item.title })), [stringSlideItems]);

  const [isLoadingCreateMyWork, setIsLoadingCreateMyWork] = useState(false);

  // 카테고리 선택 시 조회하는 템플릿 목록을 치환합니다.
  const onSelectCategory = useCallback((categoryId: string, categoryName: string) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName(categoryName);

    AmplitudeTaxonomyClient.selectIndustryCategory({
      industry_category: categoryName,
      location,
    });
  }, [location, setSelectedCategoryId]);

  // 템플릿을 선택할 시 마이워크를 생성합니다.
  const onSelectTemplate = useCallback((templateId: string) => {
    // 모바일에서는 마이워크를 생성하지 않고 /editor/mobile 페이지로 리다이렉트함
    if (isMobile) {
      router.push('/editor/mobile');

      return;
    }

    setIsLoadingCreateMyWork(true);

    selectedCategoryName &&
      AmplitudeTaxonomyClient.selectTemplate({
        industry_category: selectedCategoryName,
        location,
        id: templateId,
      });

    createMyWork
      .mutateAsync({
        templateId,
        currentCategoryId: selectedCategoryId,
      })
      .then((response) => {
        const { myWorkId } = response.data;

        router.push(`/editor?myWorkId=${myWorkId}`);
      })
      .catch((reason) => {
        const code = reason.code;

        if (code && code === 400) {
          showAccountDialog(getCreateMyWorkActionURI(router.pathname, { templateId }));
        }
        setIsLoadingCreateMyWork(false);
      });
  }, [
    showAccountDialog,
    selectedCategoryId,
    createMyWork,
    selectedCategoryName,
    location,
    router,
  ]);

  useEffect(() => {
    const item = categories.at(0);

    if (item !== undefined) {
      setSelectedCategoryId(item.id);
      setSelectedCategoryName(item.title);
    }
  }, [categories, setSelectedCategoryId]);

  return (
    <div
      className={classNames(
        'TemplateBrowse',
        props.className
      )}
    >
      <LoadingOverlay
        active={isLoadingCreateMyWork}
      />
      <CategoriesList
        activeId={selectedCategoryId}
        items={categories}
        loading={categories.length <= 0}
        css={css`
          max-width: 1136px;
          margin: auto;
          padding: 0 45px;
        `}
        onClick={({ id, title }) => onSelectCategory(id, title)}
      />
      <div
        className="TemplateBrowse__MarqueeSection"
        css={css`
          margin-top: 66px;
          padding: 10px;
          overflow-x: hidden;
          .TemplateMarquee {
            overflow-x: visible !important;
          }
        `}
      >
        <TemplateMarquee
          templates={upsideMarqueeTemplates}
          css={css`
            margin-bottom: 18px;
          `}
          onClickTemplate={({ id }) => onSelectTemplate(id)}
        />
        <TemplateMarquee
          direction="right"
          templates={downsideMarqueeTemplates}
          css={css`
            margin-bottom: 18px;
          `}
          onClickTemplate={({ id }) => onSelectTemplate(id)}
        />
      </div>
    </div>
  );
};
